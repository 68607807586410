import { type ReactElement } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';

import { NavLink } from '../NavLink';

const Wrapper = styled(Box)(() => ({
  margin: '0 1.5rem',
}));

interface IProps {
  page: string;
  href: string;
  match?: RegExp;
  onClick: () => void;
}

export const TopBarItem = ({
  page,
  href,
  onClick,
  match,
}: IProps): ReactElement => {
  const router = useRouter();

  const isActive = Boolean(
    match ? match.test(router.asPath) : router.asPath.includes(href)
  );
  return (
    <Wrapper className={`nav-link-box ${isActive ? 'active' : ''}`}>
      <NavLink title={page} href={href} onClick={onClick} isActive={isActive} />
    </Wrapper>
  );
};
