import {
  type ChangeEvent,
  type ReactElement,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

export const CountryCodes = {
  US: '+1',
  CA: '+1',
} as const;
export type CountryCode = (typeof CountryCodes)[keyof typeof CountryCodes];

type IProps = TextFieldProps & {
  countryCode?: CountryCode;
  /**
   * Validates the phone number asynchronously
   * @param value the phone number to check
   * @returns An error message if the phone number is invalid or null if it is valid
   */
  validate?: (value: string) => Promise<string | null>;
  value?: string;
};

export const PhoneNumberInput = ({
  countryCode = CountryCodes.CA,
  disabled = false,
  value = '',
  InputProps,
  onChange,
  fullWidth,
  validate,
  ...props
}: IProps): ReactElement => {
  const theme = useTheme();

  const [validating, setValidating] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const valueRef = useRef<string | null>(null);
  useEffect(() => {
    if (validate && value !== valueRef.current) {
      valueRef.current = value;
      setValidating((v) => v + 1);
      validate?.(value)
        .then((result) => {
          setValidating((v) => v - 1);
          setError(result);
        })
        .catch((e) => {
          setValidating((v) => v - 1);
          setError(e.message);
        });
    }
  }, [value, validate]);

  const onValueChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const nextValue = e.target.value;

    const re = /^(?:\+1)?\(?\d{0,3}[)-]? ?\d{0,3}[- ]?\d{0,4}$/;
    if (nextValue === '' || re.test(nextValue)) {
      e.target.value = nextValue.replace(/^\+1/, '').replace(/[^\d]/g, '');
      onChange?.(e);
    }
  };

  let endAdornment: ReactNode = null;
  if (validate) {
    if (validating) {
      endAdornment = <CircularProgress size={16} />;
    } else if (error) {
      endAdornment = (
        <Tooltip title={error}>{<CancelIcon color="error" />}</Tooltip>
      );
    } else if (value) {
      endAdornment = <CheckCircleIcon color="success" fontSize="small" />;
    }
  }

  return (
    <TextField
      variant="standard"
      type="text"
      InputLabelProps={{ shrink: true }}
      {...props}
      fullWidth={fullWidth}
      value={value}
      onChange={onValueChange}
      InputProps={{
        startAdornment: (
          <InputAdornment
            disableTypography
            position="start"
            sx={{
              color: disabled ? theme.palette.grey[400] : undefined,
            }}
          >
            {countryCode}
          </InputAdornment>
        ),
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
        ...InputProps,
      }}
    />
  );
};
