import { type ReactElement } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Link from '@mui/material/Link';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { useRouter } from 'next/router';

interface IProps extends TypographyProps {
  title: string;
  onClick?: (event?: React.KeyboardEvent | React.MouseEvent) => void;
  withBackArrow?: boolean;
  withForwardArrow?: boolean;
}

export const GoBack = ({
  title,
  onClick,
  withBackArrow,
  withForwardArrow,
  ...props
}: IProps): ReactElement => {
  const router = useRouter();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  };

  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      underline="none"
      href="#"
      onClick={handleClick}
    >
      {withBackArrow && <ArrowBackIcon />}

      <Typography variant="body1" textAlign="center" {...props}>
        {title}
      </Typography>

      {withForwardArrow && <ArrowForwardIcon />}
    </Link>
  );
};
