/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FormikErrors, type FormikTouched } from 'formik';
import get from 'lodash/get.js';

const getFirst = (
  object: FormikErrors<any> | FormikTouched<any>,
  path: string | string[]
) => {
  if (Array.isArray(path)) {
    return path.find((p) => get(object, p));
  } else {
    return get(object, path);
  }
};

export const errorProps = (
  path: string | string[],
  touched: FormikTouched<any>,
  errors: FormikErrors<any>
): {
  error?: boolean;
  helperText?: string;
} =>
  getFirst(touched, path) && getFirst(errors, path)
    ? { error: true, helperText: getFirst(errors, path) as string }
    : {};

export const errorPropsV2 = (
  path: string | string[],
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  fieldError?: string,
  fieldWarning?: string
): {
  error?: boolean;
  helperText?: string;
} =>
  getFirst(touched, path) && getFirst(errors, path)
    ? { error: true, helperText: getFirst(errors, path) as string }
    : fieldError || fieldWarning
      ? {
          error: fieldError ? true : false,
          helperText: fieldError || fieldWarning,
        }
      : {};
