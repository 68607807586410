import { type ReactElement } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import DocumentUploadBox from './DocumentUploadBox';
import DocumentUploadFilenameBox from './DocumentUploadFilenameBox';
import { type IDocumentUpload } from './types';

export const DocumentUploadError = ({
  fileName,
  uploadState,
  onRetryUploadFile,
  onTryNewFile,
}: IDocumentUpload): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <>
      <DocumentUploadBox
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
        }}
      >
        <DocumentUploadFilenameBox
          fileName={fileName}
          uploadState={uploadState}
          onRetryUploadFile={onRetryUploadFile}
        />
      </DocumentUploadBox>
      <Link
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        underline="none"
        href="#"
        onClick={onTryNewFile}
      >
        <Typography variant="subtitle1" textAlign="center">
          {t('tryUploadFile')}
        </Typography>
      </Link>
    </>
  );
};
