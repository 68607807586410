import { type ReactElement } from 'react';

import { Alert, AlertTitle } from '@mui/material';
import Stack from '@mui/material/Stack';

import DocumentUploadBox from './DocumentUploadBox';
import { type UploadState } from './types';

export const DocumentUploadInfected = ({
  fileName,
  errorMessage,
  uploadState,
}: {
  fileName: string | undefined;
  errorMessage: string;
  uploadState: UploadState;
}): ReactElement => {
  return (
    <DocumentUploadBox>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Alert severity="error">
          <AlertTitle>{errorMessage}</AlertTitle>
        </Alert>
      </Stack>
    </DocumentUploadBox>
  );
};
