import { forwardRef } from 'react';

import { OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MUISelect, { type NativeSelectProps } from '@mui/material/NativeSelect';

export interface SelectV2Props extends NativeSelectProps {
  label?: string;
  helperText?: string;
  'data-test-id'?: string;
}

export const SelectV2 = forwardRef<HTMLSelectElement, SelectV2Props>(
  (selectProps, ref) => {
    const {
      label,
      sx,
      helperText,
      error,
      required,
      'data-test-id': dataTestId,
      children,
      fullWidth,
      ...props
    } = selectProps;

    return (
      <FormControl
        variant="outlined"
        size="small"
        fullWidth={fullWidth}
        sx={sx}
      >
        {label && (
          <InputLabel required={required} error={error}>
            {label}
          </InputLabel>
        )}
        <MUISelect
          required={required}
          variant="outlined"
          input={
            <OutlinedInput
              label={label}
              sx={{
                '& .MuiOutlinedInput-notchedOutline legend': {
                  maxWidth: '100%',
                },
              }}
            />
          }
          size="small"
          error={error}
          inputProps={
            {
              variant: 'outlined',
              inputRef: ref,
              'data-test-id': dataTestId,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
          }
          {...props}
        >
          {children}
        </MUISelect>
        {helperText && (
          <FormHelperText
            sx={(theme) => ({
              mx: 0,
              mb: 0,
              fontSize: '10px',
              lineHeight: '1.66',
              marginLeft: '12px',
            })}
            error={error}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
