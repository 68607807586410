import { type ReactElement, useState } from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

interface IProps {
  fileUrls: string[];
  onClose: () => void;
  isVisible: boolean;
}

export const PreviewMultipleFiles = ({
  fileUrls,
  onClose,
  isVisible,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');

  const [currentlyViewingFileIndex, setCurrentlyViewingFileIndex] =
    useState<number>(0);
  const goToNextFile = () => {
    if (currentlyViewingFileIndex < fileUrls.length - 1)
      setCurrentlyViewingFileIndex(currentlyViewingFileIndex + 1);
    else setCurrentlyViewingFileIndex(0);
  };
  const goToPreviousFile = () => {
    if (currentlyViewingFileIndex > 0)
      setCurrentlyViewingFileIndex(currentlyViewingFileIndex - 1);
    else setCurrentlyViewingFileIndex(fileUrls.length - 1);
  };
  return (
    <Paper
      elevation={3}
      sx={{
        display: isVisible ? 'block' : 'none',
        position: 'fixed',
        top: '5%',
        right: 0,
        height: '100%',
        width: '50%',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
        height={'100%'}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="text"
            color="info"
            onClick={() => {
              setCurrentlyViewingFileIndex(0);
              onClose();
            }}
          >
            {t('close')}
          </Button>
          <Typography variant="body2">
            {t('viewingDocumentMThOfNThe', {
              currentNumber:
                fileUrls.length > 0 ? currentlyViewingFileIndex + 1 : 0,
              totalNumber: fileUrls.length,
            })}
          </Typography>
          <Box>
            <Button variant="text" color="info" onClick={goToPreviousFile}>
              {t('previous')}
            </Button>
            <Button variant="text" color="info" onClick={goToNextFile}>
              {t('next')}
            </Button>
          </Box>
        </Box>

        <iframe
          title="POI"
          src={fileUrls[currentlyViewingFileIndex]}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
    </Paper>
  );
};
