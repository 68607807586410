export const formatFileSize = (number?: number | null): string => {
  if (!number) return '0 bytes';
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
};
