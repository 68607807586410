import { type ReactElement } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';

export const getRelativeTimestamp = (
  value: DateTime | Date | string | number | null | undefined,
  relativeOptions?: Parameters<typeof DateTime.prototype.toRelative>[0],
  hideYear?: boolean
): string => {
  if (!value) {
    return '';
  }
  let date: DateTime | null = null;
  if (typeof value === 'string') {
    date = DateTime.fromISO(value);
  } else if (typeof value === 'number') {
    date = DateTime.fromMillis(value);
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else if (value instanceof DateTime) {
    date = value;
  }
  if (!date) {
    return '';
  }
  if (Math.abs(date.diffNow('days', {}).days) < 7) {
    return date.toRelative(relativeOptions) ?? '';
  }
  return hideYear
    ? date.toFormat('LLL d')
    : date.toLocaleString(DateTime.DATE_MED);
};

export const getTimestamp = (
  value: DateTime | Date | string | number | null | undefined
): string => {
  if (!value) {
    return '';
  }
  let date: DateTime | null = null;
  if (typeof value === 'string') {
    date = DateTime.fromISO(value);
  } else if (typeof value === 'number') {
    date = DateTime.fromMillis(value);
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else if (value instanceof DateTime) {
    date = value;
  }
  if (!date) {
    return '';
  }
  return date.toLocaleString(DateTime.DATETIME_MED);
};

export type RelativeTimestampProps = TypographyProps & {
  value: DateTime | Date | string | number | null | undefined;
  prefix?: string;
  suffix?: string;
  relativeOptions?: Parameters<typeof DateTime.prototype.toRelative>[0];
  hideYear?: boolean;
};

export const TimestampTooltip = ({
  value,
  children,
}: {
  value: DateTime | Date | string | number | null | undefined;
  children: React.ReactNode;
}): ReactElement => {
  return (
    <Tooltip title={getTimestamp(value)} suppressHydrationWarning>
      <span>{children}</span>
    </Tooltip>
  );
};

export const RelativeTimestamp = ({
  value,
  prefix,
  suffix,
  relativeOptions,
  hideYear,
  ...rest
}: RelativeTimestampProps): ReactElement => {
  return (
    <TimestampTooltip value={value}>
      <Typography {...rest} suppressHydrationWarning>
        {prefix && `${prefix} `}
        {getRelativeTimestamp(value, relativeOptions, hideYear)}
        {suffix && ` ${suffix}`}
      </Typography>
    </TimestampTooltip>
  );
};
