import React, { type ReactElement, type ReactNode } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { type IPage } from '../TopBar';

interface IProps {
  pages: IPage[];
  additionalItems?: ReactNode;
  onMenuOpen: (e: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  anchorElNav: null | HTMLElement;
  logoOnly?: boolean;
  noLink?: boolean;
  linkProps?: {
    href?: string;
    rel?: string;
    target?: string;
  };
  logoSrc?: string;
}

export const TopBarMobile = ({
  pages,
  additionalItems,
  onMenuOpen,
  onMenuClose,
  anchorElNav,
  logoOnly,
  noLink,
  linkProps,
  logoSrc,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();

  const logo = (
    <Image
      priority
      alt={t('appTitle')}
      src={
        logoSrc ?? `${publicRuntimeConfig.public_prefix}/images/main-logo-2.svg`
      }
      height={25}
      width={150}
      className="logo"
      css={{
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }}
    />
  );

  const logoWithLink = (
    <Link
      {...linkProps}
      href={linkProps?.href ? linkProps.href : '/'}
      underline="none"
      sx={{
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }}
    >
      {logo}
    </Link>
  );

  const resolvedLogo = noLink ? logo : logoWithLink;

  if (logoOnly) {
    return resolvedLogo;
  }

  return (
    <>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={onMenuOpen}
          color="inherit"
        >
          <MenuIcon className="menu-icon" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={onMenuClose}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {pages?.map((page) => (
            <MenuItem
              key={page.name}
              onClick={async () => {
                onMenuClose();
                await router.push(page.href);
              }}
            >
              <Typography textAlign="center">{page.name}</Typography>
            </MenuItem>
          ))}
          {additionalItems}
        </Menu>
      </Box>

      {resolvedLogo}
    </>
  );
};
