import React, { type ReactElement, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type TProps = {
  loadingPosition?: 'start' | 'center' | 'end';
  startIcon?: ReactElement | null;
  color?: 'info' | 'primary' | 'secondary' | 'error' | 'success';
  endIcon?: ReactElement | null;
  children: ReactElement;
  loading?: boolean;
  variant?: 'outlined' | 'contained' | 'text';
  onClick: () => void;
  onCancel: () => void;
  confirmationHeader: ReactElement;
  confirmationText: ReactElement;
  confirmationCancelButtonText: ReactElement;
  confirmationConfirmButtonText: ReactElement;
  disabled?: boolean;
};
export function BtnConfirm({
  loadingPosition = 'start',
  startIcon = null,
  color = 'info',
  endIcon = null,
  children,
  loading = false,
  variant = 'contained',
  onClick,
  onCancel,
  confirmationHeader,
  confirmationText,
  confirmationCancelButtonText,
  confirmationConfirmButtonText,
  disabled,
  ...rest
}: TProps): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <LoadingButton
        loading={loading}
        loadingPosition={loadingPosition}
        startIcon={startIcon}
        endIcon={endIcon}
        variant={variant}
        onClick={() => setOpen(true)}
        color={color}
        disabled={disabled}
        {...rest}
      >
        {children}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={() => {
          onCancel();
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmationHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel();
              setOpen(false);
            }}
            color="info"
          >
            {confirmationCancelButtonText}
          </Button>
          <Button
            onClick={() => {
              onClick();
              setOpen(false);
            }}
            autoFocus
            color="primary"
          >
            {confirmationConfirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
