/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { getCookie } from 'cookies-next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useClient = (pageProps: any): ApolloClient<NormalizedCacheObject> => {
  const csrfToken = getCookie('CA_CSRF_TOKEN');

  return useMemo(
    () =>
      new ApolloClient({
        uri: '/api/graphql',
        cache: new InMemoryCache().restore(pageProps.__APOLLO_CACHE__),
        headers: {
          'x-creditapp-csrf-token': `${csrfToken}`,
        },
        connectToDevTools: true,
      }),
    [pageProps.__APOLLO_CACHE__, csrfToken]
  );
};

export default useClient;
