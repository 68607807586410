import { type ReactElement } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';

import { NumericInput, type NumericInputProps } from '../NumericInput';
import { currencyToSymbolMap } from './types';

type IProps = NumericInputProps & {
  currency?: 'CAD' | 'USD';
};

export const MoneyInput = ({
  label,
  currency = 'CAD',
  variant,
  InputProps,
  disabled,
  ...props
}: IProps): ReactElement => {
  const theme = useTheme();

  return (
    <NumericInput
      label={label}
      variant={variant || 'standard'}
      InputProps={{
        sx: {
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
        },
        startAdornment: (
          <InputAdornment
            disableTypography
            position="start"
            sx={{
              color: disabled ? theme.palette.grey[400] : undefined,
            }}
          >
            {currencyToSymbolMap[currency]}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      disabled={disabled}
      {...props}
    />
  );
};
