import { type ChangeEvent, type ReactElement } from 'react';

import Close from '@mui/icons-material/Close';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Card, CardMedia, type SxProps, type Theme } from '@mui/material';
import Button from '@mui/material/Button';

interface IProps {
  value: File | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRemove: () => void;
  buttonStyle?: SxProps<Theme> | undefined;
  imageStyle?: SxProps<Theme> | undefined;
}

export const ImagePreview = ({
  value,
  handleChange,
  handleRemove,
  buttonStyle,
  imageStyle,
}: IProps): ReactElement => {
  return !value ? (
    <Button
      variant="outlined"
      color="primary"
      sx={buttonStyle}
      component="label"
    >
      <PhotoCamera />
      <input
        accept="image/png,.png,image/jpg,.jpg,.jpeg,image/heic,.heic,application/pdf,.pdf,application/json,.json,application/msword,.doc,.docx,application/vnd.ms-excel,.xls,.xlsx,text/csv,.csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,application/xml,.xml"
        type="file"
        id="select-image"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </Button>
  ) : (
    <Card sx={imageStyle}>
      <Button
        variant="contained"
        color="primary"
        component="label"
        sx={{ position: 'absolute', right: 10, top: 10 }}
        onClick={handleRemove}
      >
        <Close />
      </Button>
      <CardMedia
        component="img"
        sx={{ objectFit: 'fill', height: '100%', width: '100%' }}
        image={URL.createObjectURL(value)}
      />
    </Card>
  );
};
