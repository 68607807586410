import { type ReactElement, useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { v4 } from 'uuid';

import { useNotification } from '../ErrorContext';

export interface PreviewableFile {
  s3Path: string;
  fileName: string;
}

export interface ExternalFile {
  url: string;
  contentType: string | undefined;
  detectedMimeType: string | undefined;
}

interface IProps {
  files: PreviewableFile[];
  loadFileUrl: (s3Path: string) => Promise<ExternalFile>;
  onClose: () => void;
  isVisible: boolean;
}

export const PreviewMultipleFilesV2 = ({
  files,
  loadFileUrl,
  onClose,
  isVisible,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');
  const { setErrorNotification } = useNotification();
  const [currentlyViewingFileIndex, setCurrentlyViewingFileIndex] =
    useState<number>(0);
  const [url, setUrl] = useState<ExternalFile | null>(null);
  const urlRef = useRef({} as Record<string, ExternalFile>);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  useEffect(() => {
    if (!isVisible) {
      setCurrentlyViewingFileIndex(0);
      setUrl(null);
    }
  }, [isVisible]);
  useEffect(() => {
    const currentIndex = currentlyViewingFileIndex;
    const s3Path = files[currentlyViewingFileIndex]?.s3Path;
    if (!s3Path) return;
    if (urlRef.current[s3Path]) {
      setUrl(urlRef.current[s3Path]);
      return;
    } else {
      setUrl(null);
    }
    (async () => {
      const url = await loadFileUrl(s3Path);
      urlRef.current[s3Path] = url;
      if (currentIndex === currentlyViewingFileIndex) {
        setUrl(url);
      }
    })();
  }, [currentlyViewingFileIndex, files, loadFileUrl]);
  useEffect(() => {
    if (containerRef.current && url) {
      const embed = document.createElement('embed');
      embed.src = url.url;
      embed.type = url.detectedMimeType || '';
      containerRef.current.innerHTML = '';
      containerRef.current.appendChild(embed);
      return () => {
        embed.remove();
      };
    }
  }, [url]);

  const onDownloadFile = async (filePath: string) => {
    try {
      const {
        data: { url },
      } = await axios.post('/api/download', {
        key: filePath,
      });

      const documentId = v4();
      const link = document.createElement('a');
      link.setAttribute('id', documentId);
      link.download = 'Document';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setErrorNotification(err);
    }
  };
  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          height: '90vh',
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'relative',
          }}
        >
          <Typography
            noWrap
            variant="inherit"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: 'calc(100% - 40px)',
            }}
          >
            {files.length > 1
              ? t('documentsViewer', {
                  index: currentlyViewingFileIndex + 1,
                  total: files.length,
                })
              : t('documentViewer', {
                  fileName: files[currentlyViewingFileIndex]?.fileName,
                })}
          </Typography>
          <IconButton
            onClick={() =>
              onDownloadFile(files[currentlyViewingFileIndex]?.s3Path)
            }
          >
            <FileDownloadIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: -10,
              top: -10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          height: '100%',
        }}
      >
        <Grid
          container
          gap={1}
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
            flexFlow: 'nowrap',
          }}
        >
          {files.length > 1 && (
            <List
              sx={{
                width: '200px',
                height: '100%',
                overflowY: 'auto',
              }}
            >
              {files.map((file, index) => (
                <ListItemButton
                  key={file.s3Path}
                  onClick={() => setCurrentlyViewingFileIndex(index)}
                  selected={currentlyViewingFileIndex === index}
                >
                  {file.fileName}
                </ListItemButton>
              ))}
            </List>
          )}
          <Grid
            ref={containerRef}
            item
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: 'calc(100% - 200px)',
              flexGrow: 1,
              '& embed': {
                width: '100%',
                minHeight: '99%',
                objectFit: 'contain',
                position: 'relative',
                bottom: 0,
                top: 0,
              },
            }}
            padding={1}
            position={'relative'}
          >
            {!url && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
