import {
  type Theme,
  createTheme as createMuiTheme,
} from '@mui/material/styles';
import { Lato, Montserrat } from 'next/font/google';

const lato = Lato({
  subsets: ['latin'],
  weight: ['400', '700'],
});

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
});

const {
  palette: { augmentColor },
} = createMuiTheme();
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const extendTheme = (theme: Theme) => {
  theme.typography.hero = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '36px',
    lineHeight: '43.2px',
  };

  theme.typography.hero2 = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '30px',
  };

  theme.typography.hero3 = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '20px',
  };

  theme.typography.h1 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
  };
  theme.typography.h2 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
  };
  theme.typography.h3 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
  };
  theme.typography.h4 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
  };
  theme.typography.h5 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21.6px',
  };
  theme.typography.h6 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
  };

  theme.typography.body1 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
  };
  theme.typography.smallText = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
  };
  theme.typography.body3 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
  };

  return theme;
};

const createTheme = (): Theme =>
  extendTheme(
    createMuiTheme({
      palette: {
        primary: {
          main: '#f64f6f',
        },
        secondary: {
          dark: '#1B4B95',
          main: '#3db3f5',
          light: '#C1E0F7',
        },
        backgroundMain: '#1D3963',
        highlight: '#E0EBFD',
        info: {
          main: '#0288d1',
        },
        link: '#2151C5',
        secondaryGray: { main: '#F1F2F4', hover: '#DDDFE4' },
        tableBorder: { main: '#2e2e2e', light: '#e0e0e0' },
        tableRowHover: { main: '#f9f9fa' },
        secondaryBlue: { main: '#20254F' },
        worksheetChangedItemBackground: { main: '#E7F5FF' },
        secondaryDark: createColor('#1B4B95'),
        white: {
          main: '#ffffff',
        },
        gray: {
          light: '#F2F2F2',
          main: '#808080',
        },
        badge: {
          APPROVED: '#1B4B95',
          BOOKED: '#000000',
          DECLINED: '#EA3006',
          DRAFT: '#E0E0E0',
          CONDITIONALLY_APPROVED: '#2C821E',
          INCOMPLETE: '#FF7F05',
          DOCUMENTS_INCOMPLETE: '#682C76',
          AWAITING_DECISION: '#FFD25D',
          PENDING_BANK_CONNECTION: '#797978',
          WITHDRAWN: '#E693D7',
        },
        hitType: {
          NO_CREDIT_FILE_REQUESTED: '#FF7F05',
          HIT: '#46CF30',
          NO_HIT: '#F9471F',
          MANUAL_FILE: '#9B51E0',
          CONSUMER_DECLARATION: '#FFD25D',
          REFERRED_FILE: '#1B4B95',
          CONTACT_BUREAU_COLLECTION_DEPARTMENT: '#FF7F05',
          CREDIT_LOCK: '#FF7F05',
        },
        incomeStatus: {
          WAIVED: '#2C821E',
          VERIFIED: '#2C821E',
          REQUIRED: '#F4CC65',
          UNKNOWN: '#A0A0A0',
        },
        incomeStatusChip: {
          WAIVED: '#B5F087',
          VERIFIED: '#B5F087',
          REQUIRED: '#FFEA80',
        },
        worksheetStatus: {
          approved: '#1B4B95',
        },
        custom: {
          text: {
            main: '#4B5663',
          },
        },
        bullet: {
          orange: '#FF9F1C',
          yellow: '#F65296',
          pink: '#FFC735',
        },
        progressBar: '#91E283',
        chat: {
          received: '#d9d9d9',
          sent: '#dbedff',
          selected: '#e6f2ff',
          system: '#fddce2',
          suggestion: '#fddce2',
        },
        notificationIcon: '#3DB3F5',
        heroText: '#FFC735',
      },
      typography: {
        button: {
          textTransform: 'none',
        },
        fontFamily: [lato.style.fontFamily, montserrat.style.fontFamily].join(
          ','
        ),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 28,
            },
            sizeLarge: {
              minWidth: '210px',
              height: '38px',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: '#db3131',
              fontSize: '1.7rem',
              '&$error': {
                color: '#db3131',
              },
            },
          },
        },
      },
    })
  );

export const createTheme2 = (dark?: boolean): Theme => {
  return createMuiTheme({
    palette: {
      mode: dark ? 'dark' : 'light',
      primary: createColor('#0065FF'),
      secondary: createColor('#F1F2F4'),
      backgroundMain: '#1D3963',
      highlight: '#E0EBFD',
      info: {
        main: '#0288d1',
      },
      topBar: '#1D3963',
      startButton: 'background.paper',
      link: '#0065FF',
      worksheetChangedItemBackground: { main: '#E7F5FF' },
      secondaryBlue: { main: '#20254F' },
      secondaryGray: { main: '#F1F1F3', hover: '#DDDFE4' },
      tableBorder: { main: '#2e2e2e', light: '#e0e0e0' },
      tableRowHover: { main: '#f9f9fa' },
      secondaryDark: createColor('#0065FF'),
      white: {
        main: '#ffffff',
      },
      gray: {
        light: '#F2F2F2',
        main: '#808080',
        dark: '#47546D',
      },
      success: {
        main: '#2C821E',
      },
      lightWarning: '#FFCE73',
      badge: {
        APPROVED: '#1B4B95',
        BOOKED: '#000000',
        DECLINED: '#EA3006',
        DRAFT: '#E0E0E0',
        CONDITIONALLY_APPROVED: '#2C821E',
        INCOMPLETE: '#FF7F05',
        DOCUMENTS_INCOMPLETE: '#682C76',
        AWAITING_DECISION: '#FFD25D',
        PENDING_BANK_CONNECTION: '#797978',
        WITHDRAWN: '#E693D7',
      },
      hitType: {
        NO_CREDIT_FILE_REQUESTED: '#FF7F05',
        HIT: '#46CF30',
        NO_HIT: '#F9471F',
        MANUAL_FILE: '#9B51E0',
        CONSUMER_DECLARATION: '#FFD25D',
        REFERRED_FILE: '#1B4B95',
        CONTACT_BUREAU_COLLECTION_DEPARTMENT: '#FF7F05',
        CREDIT_LOCK: '#FF7F05',
      },
      incomeStatus: {
        WAIVED: '#2C821E',
        VERIFIED: '#2C821E',
        REQUIRED: '#F4CC65',
        UNKNOWN: '#A0A0A0',
      },
      incomeStatusChip: {
        WAIVED: '#B5F087',
        VERIFIED: '#B5F087',
        REQUIRED: '#FFEA80',
      },
      incomeAdjudicationStatus: {
        ADDITIONAL_DATA_REQUESTED: {
          light: '#0288D114',
          main: '#0288D1',
          dark: '#01579B',
        },
        APPROVED: {
          light: '#2E7D3214',
          main: '#388E3C',
          dark: '#1B5E20',
        },
        DECLINED: {
          light: '#D32F2F14',
          main: '#D32F2F',
          dark: '#B71C1C',
        },
        PENDING_INCOME: {
          light: '#0288D114',
          main: '#0288D1',
          dark: '#01579B',
        },
        PROCESSING: {
          light: '#0288D114',
          main: '#0288D1',
          dark: '#01579B',
        },
        PROCESSING_DELAY: {
          light: '#EF6C0014',
          main: '#F57C00',
          dark: '#E65100',
        },
        CLOSED: {
          light: '#75757529',
          main: '#757575',
          dark: '#424242',
        },
        PROCESSING_ERROR: {
          light: '#D32F2F14',
          main: '#D32F2F',
          dark: '#B71C1C',
        },
        WAITING_FOR_REVIEW: {
          light: '#2E7D3214',
          main: '#388E3C',
          dark: '#1B5E20',
        },
      },
      dataSourceStatus: {
        REQUESTED: {
          main: '#0288D1',
          light: '#0288D114',
        },
        PROCESSING_SUCCESS: {
          main: '#388E3C',
          light: '#2E7D3214',
        },
        PROCESSING: {
          main: '#0288D1',
          light: '#0288D114',
        },
        PROCESSING_DELAY: {
          main: '#F57C00',
          light: '#EF6C0014',
        },
        PROCESSING_ERROR: {
          main: '#D32F2F',
          light: '#D32F2F14',
        },
      },
      verificationConfidence: {
        HIGH: {
          main: '#00A607',
          light: '#00A60740',
        },
        MEDIUM: {
          main: '#EAA501',
          light: '#EAA50140',
        },
        LOW: {
          main: '#D32F2F',
          light: '#D32F2F40',
        },
        UNKNOWN: {
          main: '#E08600',
          light: '#E0860040',
        },
        COMING_SOON: {
          main: '#1B4B95',
          light: '#1B4B9540',
        },
      },
      worksheetStatus: {
        approved: '#1B4B95',
      },
      custom: {
        text: {
          main: '#4B5663',
        },
      },
      bullet: {
        orange: '#FF9F1C',
        yellow: '#F65296',
        pink: '#FFC735',
      },
      progressBar: '#91E283',
      chat: {
        received: '#d9d9d9',
        sent: '#dbedff',
        selected: '#e6f2ff',
        system: '#fddce2',
        suggestion: '#fddce2',
      },
      notificationIcon: '#3DB3F5',
      heroText: '#FFC735',
    },
    typography: {
      htmlFontSize: 14,
      fontFamily: lato.style.fontFamily,
      fontSize: 14,
      h1: {
        fontFamily: montserrat.style.fontFamily,
        fontWeight: 600,
        fontSize: 30,
        lineHeight: 'normal',
      },
      h2: {
        fontFamily: montserrat.style.fontFamily,
        fontWeight: 700,
        fontSize: 25,
        lineHeight: 'normal',
      },
      h3: {
        fontFamily: montserrat.style.fontFamily,
        fontWeight: 700,
        fontSize: 22,
        lineHeight: 'normal',
      },
      h4: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 700,
        fontSize: 20,
        lineHeight: 'normal',
      },
      h5: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 'normal',
      },
      h6: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 'normal',
      },
      subtitle1: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: 'normal',
      },
      subtitle2: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 700,
        fontSize: 12,
        lineHeight: 'normal',
      },
      largeBody: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 'normal',
      },
      body1: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 'normal',
      },
      body2: {
        fontFamily: lato.style.fontFamily,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 'normal',
      },
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          size: 'small',
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            height: 'fit-content',
            fontFamily: lato.style.fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '1.4375em',
            padding: ownerState.size === 'compact' ? '4px 10px' : '8px 10px',
            '&.MuiButton-containedSecondary': {
              color: '#000000',
            },
            '&.MuiButton-outlinedSecondary': {
              color: theme.palette.text.secondary,
              borderColor: theme.palette.text.secondary,
            },
            '&.MuiButton-textSecondary': {
              color: theme.palette.text.secondary,
            },
          }),
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            height: 'fit-content',
            fontFamily: lato.style.fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '1.4375em',
            padding: ownerState.size === 'compact' ? '4px 10px' : '8px 10px',
          }),
        },
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiIconButton-colorSecondary': {
              color: theme.palette.text.secondary,
            },
          }),
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            '&.MuiInputBase-multiline': {
              padding: 0,
            },
          },
          input: {
            '&.MuiInputBase-input': {
              padding: '8px',
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '20px',
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            '& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
              padding: 0,
            },
            '& .MuiInputBase-input.MuiAutocomplete-input': {
              padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              '&.MuiInput-input.MuiAutocomplete-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
              '&.MuiFilledInput-input.MuiAutocomplete-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
              '&.MuiOutlinedInput-input.MuiAutocomplete-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
            },
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            '& .MuiFormHelperText-root': {
              fontSize: 10,
            },
            '& .MuiInputBase-root.MuiInput-root': {
              marginTop: 0,
            },
            '& .MuiInputBase-input': {
              padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              '&.MuiInput-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
              '&.MuiFilledInput-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
              '&.MuiOutlinedInput-input': {
                padding: ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
              '&.MuiSelect-select': {
                paddingTop: ownerState.size === 'compact' ? '4px 8px' : '8px',
                paddingBottom:
                  ownerState.size === 'compact' ? '4px 8px' : '8px',
              },
            },
            '& .MuiInputLabel-root': {
              top: ownerState.size === 'compact' ? '-4px' : 0,
              transitionProperty: 'color, transform, max-width, top',
              '&.MuiInputLabel-shrink': {
                top: 0,
              },
              '&.MuiInputLabel-filled': {
                top: ownerState.size === 'compact' ? '-8px' : '-4px',
                '&.MuiInputLabel-shrink': {
                  top: '-13px',
                },
              },
              '&.MuiInputLabel-standard': {
                top: ownerState.size === 'compact' ? '-12px' : '-8px',
                '&.MuiInputLabel-shrink': {
                  top: '-7px',
                },
              },
            },
          }),
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
    },
  });
};

export default createTheme;
