import React, { type ReactElement } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import { type IDocumentUpload, UploadState } from './types';
import { shortenFileName } from './utils';

const DocumentUploadFilenameBox = ({
  fileName,
  uploadState,
  iconColor,
  onUploadFile,
  onSelectedFileRemoved,
  onRetryUploadFile,
  description,
  onDescriptionChange,
  hideDescription,
  disabled,
  hideUploadButton,
  uploadButtonText,
  uploadButtonSx,
  fileSizeInline,
  fileSize,
}: IDocumentUpload): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const ICON = {
    FILE_SELECTED: (
      <Button
        onClick={() => {
          if (onSelectedFileRemoved) onSelectedFileRemoved();
        }}
        variant="contained"
        color="info"
        size="compact"
      >
        <DeleteIcon />
      </Button>
    ),
    IN_PROGRESS: <CircularProgress />,
    SUCCESS: <CheckCircleIcon htmlColor={iconColor} />,
    ERROR: (
      <Button
        onClick={onRetryUploadFile}
        variant="contained"
        color="error"
        size="compact"
      >
        <RestartAltIcon />
      </Button>
    ),
  } as Record<UploadState, React.ReactNode>;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={1}
      >
        <Typography
          variant="body2"
          textAlign="left"
          noWrap
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {fileName
            ? `${shortenFileName(fileName)}   ${fileSizeInline ? ` - ${fileSize}` : ''}`
            : t('uploadFileNotUploaded')}
        </Typography>
        {ICON[uploadState ?? UploadState.Initial]}
      </Grid>
      {uploadState === UploadState.File_Selected && (
        <Grid item xs={12}>
          {!hideDescription && (
            <TextField
              required
              value={description}
              label={t('description')}
              sx={{ m: `${theme.spacing(4)} 0` }}
              onChange={onDescriptionChange}
              placeholder={t('descriptionPlaceholder')}
              fullWidth
            />
          )}
          {!hideUploadButton && (
            <Grid display={'flex'} justifyContent={'center'}>
              <Button
                onClick={onUploadFile}
                type="submit"
                variant="contained"
                disabled={disabled}
                sx={uploadButtonSx}
              >
                {uploadButtonText ?? t('upload')}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DocumentUploadFilenameBox;
