import React, { type ReactElement } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@mui/material/Grid';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Link, { type LinkProps } from 'next/link';

interface IProps {
  title: string;
  href?: string;
  onClick?: () => void;
  withBackArrow?: boolean;
  withForwardArrow?: boolean;
  withBottomBorder?: boolean;
  isActive?: boolean;
  CustomRightIconComponent?: React.ReactNode;
  LinkProps?: LinkProps;
  TypographyProps?: TypographyProps;
}

const useNavLinkStyles = ({
  withBottomBorder,
  isActive,
}: {
  withBottomBorder: boolean;
  isActive: boolean;
}) => {
  const theme = useTheme();

  if (isActive && withBottomBorder)
    return {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: '.3rem',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      cursor: 'pointer',
    };
  if (isActive)
    return {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      cursor: 'pointer',
    };
  if (withBottomBorder)
    return {
      color: theme.palette.text.secondary,
      borderBottom: `2px solid ${theme.palette.text.secondary}`,
      paddingBottom: '.3rem',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    };

  return {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };
};

const Container = ({
  href,
  LinkProps,
  children,
}: {
  href?: string;
  LinkProps?: LinkProps;
  children: React.ReactNode;
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!href) return <>{children}</>;
  return (
    <Link href={href} passHref {...LinkProps} css={{ textDecoration: 'none' }}>
      {children}
    </Link>
  );
};

export const NavLink = ({
  title,
  href,
  onClick,
  withBackArrow,
  withForwardArrow,
  withBottomBorder,
  CustomRightIconComponent,
  isActive,
  LinkProps,
  TypographyProps,
}: IProps): ReactElement => {
  const containerStyles = useNavLinkStyles({
    withBottomBorder: Boolean(withBottomBorder),
    isActive: Boolean(isActive),
  });

  return (
    <Container href={href} LinkProps={LinkProps}>
      <Grid
        container
        alignItems="center"
        sx={containerStyles}
        className={`nav-link-container ${isActive ? 'active' : ''}`}
        onClick={onClick}
        suppressHydrationWarning
      >
        {withBackArrow && <ArrowBackIcon />}

        <Typography
          textAlign="center"
          className={`nav-link ${isActive ? 'active' : ''}`}
          sx={{
            fontSize: 'inherit',
            lineHeight: 'inherit',
            fontWeight: 'inherit',
          }}
          {...TypographyProps}
        >
          {title}
        </Typography>

        {withForwardArrow && !CustomRightIconComponent && <ArrowForwardIcon />}
        {Boolean(CustomRightIconComponent) && CustomRightIconComponent}
      </Grid>
    </Container>
  );
};
