import { type ReactNode, useState } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import useTranslation from 'next-translate/useTranslation';

import { DatePicker } from '.';

export interface DateRangeMenuProps {
  from: string | null;
  setFrom: (value: string | null) => void;
  to: string | null;
  setTo: (value: string | null) => void;
  onClose: () => void;
}

export const DateRangeMenu = ({
  from,
  setFrom,
  to,
  setTo,
  onClose,
}: DateRangeMenuProps): ReactNode => {
  const { t } = useTranslation('common');
  const [mode, setMode] = useState<'to' | 'from' | 'between'>(
    from && to ? 'between' : from ? 'from' : to ? 'to' : 'between'
  );
  const [fromValue, setFromValue] = useState<string | null>(from);
  const [toValue, setToValue] = useState<string | null>(to);
  const [error, setError] = useState<string | null>(null);
  const onUpdate = () => {
    switch (mode) {
      case 'to':
        setFrom(null);
        setTo(toValue);
        break;
      case 'from':
        setFrom(fromValue);
        setTo(null);
        break;
      case 'between':
        if (fromValue && toValue && fromValue > toValue) {
          setError(t('dateRangeError'));
          return;
        }
        setFrom(fromValue);
        setTo(toValue);
        break;
    }
    onClose();
  };
  return (
    <Grid container direction="column" gap={1} px={2}>
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setError(null);
          setMode(e.target.value as typeof mode);
        }}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel value="from" control={<Radio />} label={t('from')} />
        <FormControlLabel value="to" control={<Radio />} label={t('to')} />
        <FormControlLabel
          value="between"
          control={<Radio />}
          label={t('between')}
        />
      </RadioGroup>
      <Grid container alignItems="center" gap={2}>
        {(mode === 'from' || mode === 'between') && (
          <DatePicker
            allowClear
            value={fromValue}
            onChange={(v) => {
              setError(null);
              setFromValue(v);
            }}
            textSx={{
              minWidth: 'unset',
            }}
            inputProps={{
              variant: 'outlined',
              inputProps: {
                sx: {
                  py: 0.5,
                  fontSize: '14px',
                  lineHeight: '16.8px',
                  fontWeight: 600,
                  width: 106,
                },
              },
            }}
          />
        )}
        {mode === 'between' && t('to')}
        {(mode === 'to' || mode === 'between') && (
          <DatePicker
            allowClear
            value={toValue}
            onChange={(v) => {
              setError(null);
              setToValue(v);
            }}
            textSx={{
              minWidth: 'unset',
            }}
            inputProps={{
              variant: 'outlined',
              inputProps: {
                sx: {
                  py: 0.5,
                  fontSize: '14px',
                  lineHeight: '16.8px',
                  fontWeight: 600,
                  width: 106,
                },
              },
            }}
          />
        )}
      </Grid>
      {error && (
        <Grid container sx={{ mt: -1 }}>
          <Typography
            variant="smallText"
            component="span"
            sx={{
              color: 'error.main',
            }}
          >
            {error}
          </Typography>
        </Grid>
      )}
      <Grid container justifyContent="flex-end">
        <Button onClick={onUpdate}>{t('update')}</Button>
      </Grid>
    </Grid>
  );
};
