import { type Theme } from '@mui/material';
import getConfig from 'next/config';

import { createTheme2 } from '@credit-app/theme';

const getTheme = (host: string): Theme => {
  let theme2 = createTheme2();
  const { publicRuntimeConfig } = getConfig();
  if (host?.includes('eqbank')) {
    theme2 = {
      ...theme2,
      palette: {
        ...theme2.palette,
        primary: {
          ...theme2.palette.primary,
          main: '#c33991',
        },
      },
      logo: `${publicRuntimeConfig.public_prefix}/images/logos/eq.svg`,
    };
  }
  if (host?.includes('autocapitalcanada')) {
    theme2 = {
      ...theme2,
      palette: {
        ...theme2.palette,
        primary: {
          ...theme2.palette.primary,
          main: '#d19434',
        },
      },
      logo: `${publicRuntimeConfig.public_prefix}/images/logos/acc.svg`,
      topBarLogoHeight: '40px',
    };
  }
  return theme2;
};

export default getTheme;
