export const shortenFileName = (
  fileName: string | null | undefined,
  length = 40
): string | undefined => {
  const lastIndex = fileName?.lastIndexOf('.');
  const name = fileName?.slice(0, lastIndex);
  const extension = fileName?.slice(lastIndex);
  return `${
    name && name.length > 40 ? `${name.slice(0, 40)}...` : name
  }${extension}`;
};
