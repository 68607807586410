import { type ReactElement } from 'react';

import { Button, Grid } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

export const Fallback = ({
  resetError,
}: {
  resetError: () => void;
}): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <h1>{t('somethingWentWrong')}</h1>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={resetError}>
          {t('retry')}
        </Button>
      </Grid>
    </Grid>
  );
};
