import { type ReactElement, type ReactNode } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Image from 'next/image';

import { type IPage } from '../TopBar';
import { TopBarItem } from '../TopBarItem';

interface IProps {
  pages: IPage[];
  additionalItems?: ReactNode;
  onNavItemClick: () => void;
  logoOnly?: boolean;
  noLink?: boolean;
  linkProps?: {
    href?: string;
    rel?: string;
    target?: string;
  };
  logoSrc?: string;
}

export const TopBarDesktop = ({
  pages,
  additionalItems,
  onNavItemClick,
  logoOnly,
  noLink,
  linkProps,
  logoSrc,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { publicRuntimeConfig } = getConfig();

  const logo = (
    <Image
      priority
      alt={t('appTitle')}
      src={
        logoSrc ?? `${publicRuntimeConfig.public_prefix}/images/main-logo-2.svg`
      }
      height={25}
      width={150}
      className="logo"
      css={{
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'inline-block',
        },
      }}
    />
  );

  const logoWithLink = (
    <Link
      {...linkProps}
      href={linkProps?.href ? linkProps?.href : '/'}
      underline="none"
    >
      {logo}
    </Link>
  );

  const resolvedLogo = noLink ? logo : logoWithLink;

  if (logoOnly) return resolvedLogo;

  return (
    <>
      {resolvedLogo}
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          overflowX: 'auto',
          marginLeft: 2,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {pages?.map((page) => (
          <TopBarItem
            key={page.name}
            page={page.name}
            href={page.href}
            onClick={onNavItemClick}
            match={page.match}
          />
        ))}
        {additionalItems}
      </Box>
    </>
  );
};
