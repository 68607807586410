import { type ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import DocumentUploadBox from './DocumentUploadBox';
import DocumentUploadFilenameBox from './DocumentUploadFilenameBox';
import { type IDocumentUpload } from './types';

export const DocumentUploadInProgress = ({
  fileName,
  progress,
  uploadState,
  onUploadFile,
  onSelectedFileRemoved,
  description,
  onDescriptionChange,
  hideDescription,
  disabled,
  hideUploadButton,
  uploadButtonText,
  uploadButtonSx,
}: IDocumentUpload): ReactElement => {
  const theme = useTheme();
  return (
    <DocumentUploadBox>
      <Stack
        direction="column"
        rowGap={theme.spacing(1)}
        sx={{ width: '100%' }}
      >
        <DocumentUploadFilenameBox
          fileName={fileName}
          uploadState={uploadState}
          onUploadFile={onUploadFile}
          onSelectedFileRemoved={onSelectedFileRemoved}
          description={description}
          onDescriptionChange={onDescriptionChange}
          hideDescription={hideDescription}
          disabled={disabled}
          hideUploadButton={hideUploadButton}
          uploadButtonText={uploadButtonText}
          uploadButtonSx={uploadButtonSx}
        />
      </Stack>
    </DocumentUploadBox>
  );
};
