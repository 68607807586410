import { type ReactElement, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from '@mui/material/TextField';

export const DisplaySin = ({
  value,
  fullWidth,
  ...props
}: TextFieldProps): ReactElement => {
  const [showSIN, updateShowSIN] = useState<boolean>(false);
  const toggleShowSIN = () => updateShowSIN(!showSIN);

  return (
    <TextField
      value={value || ''}
      disabled={true}
      variant="standard"
      type={showSIN ? 'text' : 'password'}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowSIN} onMouseDown={toggleShowSIN}>
              {showSIN ? (
                <VisibilityIcon sx={{ width: '1rem', height: '1rem' }} />
              ) : (
                <VisibilityOffIcon sx={{ width: '1rem', height: '1rem' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
