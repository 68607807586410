import { type ChangeEvent } from 'react';

import { type SxProps } from '@mui/material/styles';

export const UploadState = {
  Initial: 'INITIAL',
  File_Selected: 'FILE_SELECTED',
  In_Progress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  Infected: 'INFECTED',
  Error: 'ERROR',
} as const;

export type UploadState = (typeof UploadState)[keyof typeof UploadState];

export const DownloadState = {
  Initial: 'INITIAL',
  In_Progress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  Error: 'ERROR',
} as const;

export type DownloadState = (typeof DownloadState)[keyof typeof DownloadState];

export const DeleteState = {
  Initial: 'INITIAL',
  In_Progress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  Error: 'ERROR',
} as const;

export type DeleteState = (typeof DeleteState)[keyof typeof DeleteState];

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export interface IDocumentUpload {
  fileName?: string;
  fileSize?: string;
  onUpload?: () => void;
  onFileSelected?: (files: Array<File> | []) => Promise<void> | void;
  onUploadFile?: () => Promise<void> | void;
  onSelectedFileRemoved?: () => void;
  onRetryUploadFile?: () => void;
  progress?: number | null;
  uploadState?: UploadState;
  iconColor?: string;
  description?: string;
  onDescriptionChange?: (event: InputChange) => void;
  hideDescription?: boolean;
  isMobile?: boolean;
  maxFiles?: number | undefined;
  disabled?: boolean;
  onTryNewFile?: () => void;
  hideUploadButton?: boolean;
  uploadButtonText?: string;
  sx?: SxProps;
  uploadButtonSx?: SxProps;
  accept?: Record<string, string[]>;
  fileSizeInline?: boolean;
  maxFileSize?: number;
}
