import React, { createContext, useCallback, useState } from 'react';

import { Button, Grid, Paper } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

interface IProps {
  children: React.ReactNode;
}

interface IDocumentPreviewContextValue {
  setDocumentPreview: (path: string) => void;
}

const defaultDocumentPreviewContextValue: IDocumentPreviewContextValue = {
  setDocumentPreview: () => null,
};

export const DocumentPreviewContext =
  createContext<IDocumentPreviewContextValue>(
    defaultDocumentPreviewContextValue
  );

export const DocumentPreviewProvider: React.FC<IProps> = ({ children }) => {
  const [document, setPreview] = useState<string>('');
  const { t } = useTranslation('common');

  const setDocumentPreview = useCallback(
    (path: string) => {
      setPreview(path);
    },
    [setPreview]
  );

  const documentPreviewContextApi = {
    setDocumentPreview,
  };
  return (
    <DocumentPreviewContext.Provider value={documentPreviewContextApi}>
      {children}
      {document.length > 0 && (
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            height: '100%',
            width: '50%',
            zIndex: 9999,
          }}
        >
          <Grid
            container
            direction="column"
            sx={{ position: 'relative' }}
            height={'100%'}
            flexWrap="nowrap"
            alignItems="flex-start"
          >
            <Button variant="text" color="info" onClick={() => setPreview('')}>
              {t('close')}
            </Button>
            <iframe
              title="POI"
              src={`${document}`}
              style={{ height: '100%', width: '100%' }}
            />
          </Grid>
        </Paper>
      )}
    </DocumentPreviewContext.Provider>
  );
};

export const useDocumentPreview = (): IDocumentPreviewContextValue => {
  return React.useContext(DocumentPreviewContext);
};
