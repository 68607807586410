import { type ChangeEventHandler, type ReactElement } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import { NumericInput, type NumericInputProps } from '../NumericInput';

type OdometerUnit = 'KILOMETERS' | 'MILES';

type OdometerInputProps = NumericInputProps & {
  unit?: OdometerUnit | null;
  unitInputName?: string;
  onUnitChange?: ChangeEventHandler<HTMLSelectElement>;
};

export const OdometerInput = ({
  unit,
  unitInputName,
  onUnitChange,
  InputProps,
  disabled,
  ...props
}: OdometerInputProps): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <NumericInput
      InputProps={{
        endAdornment: (
          <InputAdornment
            disableTypography
            position="end"
            sx={{
              color: disabled ? theme.palette.grey[400] : undefined,
            }}
          >
            <NativeSelect
              disableUnderline
              name={unitInputName}
              disabled
              value={unit}
              onChange={onUnitChange}
              sx={{
                '.MuiNativeSelect-select.MuiInput-input': { width: 30, pr: 0 },
                '.MuiNativeSelect-icon': { display: 'none' },
              }}
            >
              <option value="KILOMETERS">{t('odometerUnits.km')}</option>
            </NativeSelect>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      sx={{ width: 200 }}
      disabled={disabled}
      {...props}
    />
  );
};
