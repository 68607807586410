/**
 * Validate a Canadian Social Insurance Number (SIN)
 * @param  {str} sin   A 9-digit Canadian SIN
 * @return {bool}      Validity of the input SIN
 */
export function validateSIN(sin: string): boolean {
  //strip spaces from sing
  sin = sin.replace(/\s/g, '');

  if (sin.length === 9) {
    // convert to an array & pop off the check digit
    const sinArr = sin.split('');
    const check = Number(sinArr.pop());

    const even = sinArr
      // take the digits at the even indices
      .filter(function (_, i) {
        return i % 2;
      })
      // multiply them by two
      .map(function (n) {
        return Number(n) * 2;
      })
      // and split them into individual digits
      .join('')
      .split('');

    const tot = sinArr
      // take the digits at the odd indices
      .filter(function (_, i) {
        return !(i % 2);
      })
      // concatenate them with the transformed numbers above
      .concat(even)
      // it's currently an array of strings; we want numbers
      .map(function (n) {
        return +n;
      })
      // and take the sum
      .reduce(function (acc, cur) {
        return acc + cur;
      });

    // compare the result against the check digit
    return check === (10 - (tot % 10)) % 10;
  } else {
    return false;
  }
}
