import React, { type ReactElement, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from '@mui/material/TextField';

export const SINInput = ({
  label,
  value,
  onChange,
  fullWidth,
  suppressHydrationWarning,
  show,
  ...props
}: TextFieldProps & {
  show?: boolean;
}): ReactElement => {
  const [showSIN, updateShowSIN] = useState<boolean>(Boolean(show));
  const toggleShowSIN = () => updateShowSIN(!showSIN);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;

    const re = /^\d{0,3} ?\d{0,3} ?\d{0,3}$/;
    if (nextValue === '' || re.test(nextValue)) {
      e.target.value = nextValue.replace(/ /g, '');
      onChange?.(e);
    }
  };

  return (
    <TextField
      label={label}
      value={value || ''}
      onChange={onValueChange}
      variant="standard"
      type={showSIN ? 'text' : 'password'}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowSIN} onMouseDown={toggleShowSIN}>
              {showSIN ? (
                <VisibilityIcon sx={{ width: '1rem', height: '1rem' }} />
              ) : (
                <VisibilityOffIcon sx={{ width: '1rem', height: '1rem' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        width: fullWidth ? undefined : '15rem',
      }}
      fullWidth={fullWidth}
      {...props}
    />
  );
};
