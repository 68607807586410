import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { type DateTime, Info } from 'luxon';

/**
 * This class forces luxon to use Sunday as the start of the week
 */
export class CustomLuxonUtils extends AdapterLuxon {
  public getWeekdays = (): string[] => {
    const days = Info.weekdaysFormat('narrow', { locale: this.locale });
    const lastDay = days.pop();
    if (lastDay) {
      days.unshift(lastDay);
    }
    return days;
  };

  public getWeekArray = (date: DateTime): DateTime[][] => {
    const { days } = date
      .endOf('month')
      .endOf('week')
      .minus({ days: 1 })
      .diff(date.startOf('month').startOf('week').minus({ days: 1 }), 'days')
      .toObject();

    const weeks: DateTime[][] = [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    new Array<number>(Math.round(days!))
      .fill(0)
      .map((_, i) => i)
      .map((day) =>
        date
          .startOf('month')
          .startOf('week')
          .minus({ days: 1 })
          .plus({ days: day })
      )
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks;
  };
}
