import { type ReactNode, useState } from 'react';

import { DateTime } from 'luxon';
import useTranslation from 'next-translate/useTranslation';

import { FilledSelect } from '.';
import { DateRangeMenu } from '..';

export interface DateRangeSelectProps {
  label: string;
  from: string | null;
  setFrom: (value: string | null) => void;
  to: string | null;
  setTo: (value: string | null) => void;
}

export const DateRangeSelect = ({
  label,
  from,
  setFrom,
  to,
  setTo,
}: DateRangeSelectProps): ReactNode => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const dateFormat = t('luxonDateFormat');
  const getFormattedDate = (date: string) => {
    const utc = DateTime.fromISO(date, { zone: 'utc' });
    const local = DateTime.local(utc.year, utc.month, utc.day);
    return local.toFormat(dateFormat);
  };
  const getValueText = () => {
    if (from && to) {
      return `${getFormattedDate(from)} - ${getFormattedDate(to)}`;
    }
    if (from) {
      return `${t('from')} ${getFormattedDate(from)}`;
    }
    if (to) {
      return `${t('to')} ${getFormattedDate(to)}`;
    }
    return '';
  };
  return (
    <FilledSelect
      label={label}
      variant="filled"
      hasValue={Boolean(from || to)}
      valueText={getValueText()}
      onOpen={() => setOpen(true)}
      open={open}
      onClose={() => setOpen(false)}
      onChange={() => {
        setFrom(null);
        setTo(null);
      }}
    >
      <DateRangeMenu
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        onClose={() => setOpen(false)}
      />
    </FilledSelect>
  );
};
