import { type ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import DocumentUploadBox from './DocumentUploadBox';
import DocumentUploadFilenameBox from './DocumentUploadFilenameBox';
import { type IDocumentUpload } from './types';

export const DocumentUploadSuccess = ({
  fileName,
  fileSize,
  uploadState,
  disabled,
  fileSizeInline,
}: IDocumentUpload): ReactElement => {
  const theme = useTheme();

  return (
    <DocumentUploadBox>
      <Stack direction="column" sx={{ width: '100%' }}>
        <DocumentUploadFilenameBox
          fileName={fileName}
          uploadState={uploadState}
          iconColor={theme.palette.success.main}
          disabled={disabled}
          fileSizeInline={fileSizeInline}
          fileSize={fileSize}
        />
        {!fileSizeInline && <Typography>{fileSize}</Typography>}
      </Stack>
    </DocumentUploadBox>
  );
};
