import { type ReactElement } from 'react';

import Typography, { type TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';

export type DateValueProps = TypographyProps & {
  value: DateTime | Date | string | number | null | undefined;
  hideYear?: boolean;
  prefix?: string;
  suffix?: string;
  showTime?: boolean;
  fromTimestamp?: boolean;
};

export const getDateString = ({
  value,
  hideYear,
  showTime,
  fromTimestamp,
}: {
  value: DateTime | Date | string | number | null | undefined;
  hideYear?: boolean;
  showTime?: boolean;
  fromTimestamp?: boolean;
}): string => {
  if (!value) {
    return '';
  }
  let date: DateTime | null = null;
  if (typeof value === 'string') {
    date = DateTime.fromISO(
      value,
      !showTime && !fromTimestamp ? { zone: 'utc' } : {}
    );
  } else if (typeof value === 'number') {
    date = DateTime.fromMillis(
      value,
      !showTime && !fromTimestamp ? { zone: 'utc' } : {}
    );
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(
      value,
      !showTime && !fromTimestamp ? { zone: 'utc' } : {}
    );
  } else if (value instanceof DateTime) {
    date = value;
  }
  if (!date) {
    return '';
  }
  return hideYear
    ? date.toFormat(showTime ? 'LLL d, t' : 'LLL d')
    : date.toLocaleString(showTime ? DateTime.DATETIME_MED : DateTime.DATE_MED);
};

export const DateValue = ({
  value,
  prefix,
  suffix,
  hideYear,
  showTime,
  fromTimestamp,
  ...rest
}: DateValueProps): ReactElement => (
  <Typography {...rest}>
    {prefix && `${prefix} `}
    {getDateString({ value, hideYear, showTime })}
    {suffix && ` ${suffix}`}
  </Typography>
);
