import { type ReactElement } from 'react';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import DocumentUploadBox from './DocumentUploadBox';
import { type IDocumentUpload } from './types';

export const DocumentUpload = ({
  onFileSelected,
}: IDocumentUpload): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const fileTypes = [
    'image/gif',
    'image/heic',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ];

  return (
    <DocumentUploadBox>
      <Stack
        columnGap={theme.spacing(1)}
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UploadFileIcon />
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component="label"
        >
          {t('chooseFile')}
          <input
            hidden
            accept={fileTypes.join(',')}
            type="file"
            onChange={async (e) => {
              if (onFileSelected) {
                if (e.target && e.target?.files && e.target?.files.length > 0) {
                  await onFileSelected([e.target?.files[0]]);
                } else {
                  await onFileSelected([]);
                }
              }
            }}
          />
        </Button>
      </Stack>
    </DocumentUploadBox>
  );
};
