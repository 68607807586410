import { type ReactElement } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { type TextFieldProps } from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { type SxProps } from '@mui/system';
import {
  type DatePickerProps,
  DatePicker as MUIDatePicker,
} from '@mui/x-date-pickers/DatePicker';
import { DateTime, type DateTimeMaybeValid } from 'luxon';

type Props = Omit<
  DatePickerProps<DateTime<boolean>>,
  'renderInput' | 'value' | 'onChange'
> & {
  inputProps?: TextFieldProps;
  allowClear?: boolean;
  value?: string | null;
  onChange?: (value: string | null) => void;
  'data-test-id'?: string;
  textSx?: SxProps;
};

const INPUT_FORMAT = 'M/d/yyyy';

export const DatePicker = ({
  inputProps = {},
  allowClear,
  onChange,
  value,
  'data-test-id': dataTestId,
  textSx,
  ...props
}: Props): ReactElement => {
  const theme = useTheme();
  const utcValue: DateTimeMaybeValid = DateTime.fromISO(value ?? '', {
    zone: 'utc',
  });
  const localValue: DateTimeMaybeValid = DateTime.local(
    utcValue.year,
    utcValue.month,
    utcValue.day
  );
  const onDateChange = (date: DateTime | null) => {
    if (date) {
      if (date.isValid) {
        const utcDate: DateTimeMaybeValid = DateTime.utc(
          date.year,
          date.month,
          date.day
        );
        onChange?.(utcDate.toISO());
      }
    } else {
      onChange?.(null);
    }
  };

  return (
    <MUIDatePicker<DateTime<boolean>>
      {...props}
      format={INPUT_FORMAT}
      value={localValue.isValid ? localValue : null}
      onChange={onDateChange}
      desktopModeMediaQuery={theme.breakpoints.up('md')}
      slotProps={{
        textField: ({ slotProps, ...props }) => ({
          ...props,
          variant: props.variant ?? 'standard',
          InputLabelProps: { shrink: true },
          suppressHydrationWarning: true,
          'data-test-id': dataTestId,
          sx: {
            minWidth: 160,
            '& .MuiIconButton-root.clear': {
              visibility: 'hidden',
            },
            '&:hover': {
              '& .MuiIconButton-root.clear': {
                visibility: 'visible',
              },
            },
            ...textSx,
          },
          InputProps: {
            ...props.InputProps,
            suppressHydrationWarning: true,
            endAdornment: (
              <>
                {allowClear && value ? (
                  <IconButton
                    size="small"
                    className="clear"
                    onClick={() => {
                      onDateChange(null);
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : null}
                {props.InputProps?.endAdornment}
              </>
            ),
          },
          ...inputProps,
        }),
      }}
    />
  );
};
