// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const getErrorStringFromError = (error: any): string => {
  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (error?.message) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  }
  return 'Unknown error';
};
