import { forwardRef } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MUISelect, { type NativeSelectProps } from '@mui/material/NativeSelect';

export interface IDataItem {
  label: string;
  value: string;
}

export interface IProps extends NativeSelectProps {
  label?: string;
  helperText?: string;
  data: IDataItem[];
  'data-test-id'?: string;
}

export const Select = forwardRef<HTMLSelectElement, IProps>(
  (selectProps, ref) => {
    const {
      label,
      data,
      sx,
      helperText,
      error,
      required,
      'data-test-id': dataTestId,
      ...props
    } = selectProps;

    return (
      <FormControl
        sx={{
          width: 'min-content',
          ...sx,
        }}
      >
        {label && (
          <InputLabel
            required={required}
            sx={{
              position: 'absolute',
              transform: 'translate(0, -1.5px) scale(0.75)',
            }}
            error={error}
          >
            {label}
          </InputLabel>
        )}
        <MUISelect
          required={required}
          variant="standard"
          IconComponent={KeyboardArrowDownIcon}
          error={error}
          inputProps={
            {
              inputRef: ref,
              'data-test-id': dataTestId,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
          }
          {...props}
        >
          {data.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </MUISelect>
        {helperText && (
          <FormHelperText
            sx={{
              mx: 0,
              mb: 0,
            }}
            error={error}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
