import { type ReactElement } from 'react';

import {
  Unstable_NumberInput as BaseNumberInput,
  type NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { blue, grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

export const NumericInputV2 = ({
  label,
  helperText,
  error,
  endAdornment,
  startAdornment,
  ...rest
}: NumberInputProps & {
  label?: ReactElement;
  helperText?: ReactElement | null;
  error?: boolean;
  endAdornment?: ReactElement | null;
  startAdornment?: ReactElement | null;
}): ReactElement => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          borderBottom: `1px solid ${
            error ? theme.palette.error.main : theme.palette.grey[400]
          }`,
          mx: 1,
          '&:focus-within': {
            borderBottom: `1px solid ${theme.palette.grey[800]}`,
          },
        }}
      >
        {label && label}
        <Grid container alignItems="center" flexWrap="nowrap">
          {startAdornment && <Grid item>{startAdornment}</Grid>}
          <Grid item flexGrow={1}>
            <BaseNumberInput
              slots={{
                root: InputRoot,
                input: InputElement,
                incrementButton: Button,
                decrementButton: Button,
              }}
              slotProps={{
                incrementButton: {
                  children: <span className="arrow">▴</span>,
                },
                decrementButton: {
                  children: <span className="arrow">▾</span>,
                },
              }}
              {...rest}
            />
          </Grid>
          {endAdornment && <Grid item>{endAdornment}</Grid>}
        </Grid>
      </Box>
      {helperText && helperText}
    </>
  );
};

const InputRoot = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.grey[900]};
  background: ${'#fff'};
  display: grid;
  grid-template-columns: auto 1fr auto 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  align-items: center;
  padding: 4px;
  &.${numberInputClasses.focused} {
    box-shadow: 1px 1px -1px 1px ${theme.palette.grey[400]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);

const Button = styled('button')(
  ({ theme }) => `
  // display: flex;
  display: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 20px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 4/5;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 4/5;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);
