import { type ReactElement, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';

type Action = {
  text: string;
  onClick: () => void;
  isDisabled: boolean;
};
interface IProps {
  id: string;
  actions: Action[];
  children: ReactElement;
  showIcon?: boolean;
  [x: string]: unknown;
}
export function DropdownButton({
  id,
  actions,
  children,
  showIcon = true,
  ...rest
}: IProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...rest}
      >
        <Grid container alignItems="center">
          <Grid item xs={showIcon ? 11 : 12}>
            {children}
          </Grid>
          {showIcon && (
            <Grid xs={1} display="flex" alignItems="center">
              <ArrowDropDownIcon sx={{ borderLeft: 'solid 1px #FFF' }} />
            </Grid>
          )}
        </Grid>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: theme.spacing(0.4), marginLeft: theme.spacing(1.2) }}
      >
        {actions && actions.length > 0 && (
          <List>
            {actions.map((action, index) => (
              <>
                <ListItem
                  key={action.text.replace(/' '/g, '-')}
                  sx={{ minWidth: theme.spacing(24) }}
                  disablePadding
                >
                  <ListItemButton onClick={action.onClick}>
                    <ListItemText primary={action.text} />
                  </ListItemButton>
                </ListItem>
                {index < actions.length - 1 && <Divider variant="middle" />}
              </>
            ))}
          </List>
        )}
      </Popover>
    </Box>
  );
}
